<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1100"
    scrollable
    @click:outside="goBack"
  >
    <v-card>
      <v-card-title class="pa-0">
        <div class="pt-4 px-4 pb-1">
          {{ selectedProject.title }} {{ $t('projects.tabs.stats').toLowerCase() }}
        </div>

        <v-tabs>
          <v-tab
            :to="{ name: 'projectPointStatistics', query: this.projectPointStatisticsFilterParams }"
            >{{ $t('projects.closed_points') }}</v-tab
          >
          <v-tab
            :to="{
              name: 'projectTimeSpentStatistics',
            }"
            >{{ $t('projects.general.hours_passed') }}</v-tab
          >
        </v-tabs>
      </v-card-title>

      <v-card-text class="px-0">
        <v-tabs-items>
          <router-view />
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="goBack">
          {{ $t('general.controls.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProjectPageStatisticsTabs',

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectPointStatistics', ['projectPointStatisticsFilterParams']),
  },

  methods: {
    goBack() {
      this.$router.push({ name: 'project' });
    },
  },
};
</script>

<style scoped></style>
